

ngb-datepicker.show {
  z-index: 9999 !important;
  background-color: #fff;
  border: 1px solid #ababab;
}

ngb-datepicker .custom-select {
  margin-top: 0px !important;
}

ngb-datepicker .ngb-dp-day .text-white {
  color: #333 !important;
  font-weight: bold !important;
}

  ngb-datepicker .ngb-dp-day .text-white.active {
    border-color: #059bd2 !important;
  }

select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}


table.dataTable {
  clear: none !important;
}

.reconciliation-search-box {
  background-color: #f3f3f3;
}

.reconciliation-result .title {
  padding: 0px 10px;
  font-size: 17px !important;
  color: #8c8c8c;
  width: 90%;
  margin-top: 2px;
  position: absolute;
  display: inline;
}

.reconciliation-result .value {
  display: inline-block;
  padding: 0px 10px;
  font-size: 17px !important;
  color: #333;
  font-weight: bolder;
}

.reconciliation-result .link {
  display: inline-block;
  padding: 0px 10px;
  font-size: 17px !important;
  color: #333;
  position: absolute;
  right: 0px;
}

table.dataTable.no-footer {
  border-bottom: 0px solid #111111 !important;
}

table.dataTable .dataTables_length select, table.dataTable .dataTables_length input {
  display: inline-block;
}

.dataTables_wrapper {
  background-color: #f3f3f3;
}

  .dataTables_wrapper .dataTables_length {
    position: absolute;
    bottom: 17px;
    margin-left: 240px;
    width: 150px;
  }

    .dataTables_wrapper .dataTables_length select {
      display: inline;
      width: 55px;
      margin: 0px 4px;
      height: 1.8rem;
      padding: 2px;
    }

    .dataTables_wrapper .dataTables_length label {
      display: inline;
      width: 100%
    }

  .dataTables_wrapper .dataTables_filter {
    display: none !important;
  }

  .dataTables_wrapper .dataTables_info {
    padding-bottom: 18px;
    padding-left: 13px;
  }

  .dataTables_wrapper .dataTables_paginate {
    padding-right: 8px;
  }

.remv-box-layout {
  border: 0px solid #c4c4c4;
}

table.dataTable.card-table > thead > tr > th {
  border-bottom: 0px solid #c4c4c4 !important;
  border-top: 0px solid #c4c4c4 !important;
}

table.dataTable.card-table > tbody > tr > td {
  border-bottom: 0px solid #c4c4c4 !important;
  border-top: 1px solid #c4c4c4 !important;
}

table.dataTable input[type=checkbox] {
  left: 0px;
  position: relative;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0.2em 0.5em !important;
}

table.dataTable tbody .td {
  background-color: #fff;
}

#avaHeader-content {
  position: absolute;
  top: 0px;
  left: 227px;
}

  #avaHeader-content img {
    margin-top: 7px;
  }

.row-block-0-8-0-8 {
  display: block !important;
  padding: 0% 8% 0% 8%;
}

.row-contents-0-8-0-8 {
  display: contents !important;
  padding: 0% 8% 0% 8%;
}

.row-block-0-6-0-6 {
  display: block !important;
  padding: 0% 6% 0% 6%;
}

/* Header.component.html CSS - START */

.loginDetailHeader {
  text-align: right !important;
  padding-right: 0px;
  padding-top: 12px;
  right: 0px;
  padding-right: 30px;
}

.loggedInContainer {
  width: auto;
  position: absolute;
  right: 30px;
  top: -34px;
}

  .loggedInContainer .userLabel {
    padding-bottom: 5px;
    font-size: 14px !important;
  }

    .loggedInContainer .userLabel .title {
      color: #72767e;
    }

  .loggedInContainer .logout {
    padding-top: 10px;
    text-decoration: none;
  }

.displayNone {
  display: none;
}

/* Header.component.html CSS - END */

/* SignIn.component.html CSS - START */

.signInContainer .heading-message {
  font-size: 1.1rem;
  padding: 42px;
}

.padding-0-30-0-30 {
  padding: 0px 30px 0px 30px;
}

.signInContainer .qbo-btn-container {
  font-size: 1.1rem;
  padding: 42px;
}

.signInContainer .processing-dialog {
  width: 118px;
  margin-top: 20px;
}

.signInContainer .videoIframeContainer {
  padding-right: 50px;
}

.signInContainer .videoIframe {
  height: 300px;
  width: 100%;
  border: 0px;
  padding: 0px 10px 0px 30px;
}

.signInContainer .note {
  font-size: 10px;
  color: Blue;
  margin-top: 20px;
}
/* SignIn.component.html CSS - END */


/* ItemManagement.component.html CSS - START*/

#frmItemManagement span.status-Fail {
  background-color: red;
  color: #fff;
  padding: 3px;
}

#frmItemManagement span.status-Updated {
  background-color: green;
  color: #fff;
  padding: 3px;
}

#frmItemManagement span.status-New {
  background-color: yellow;
  color: #000;
  padding: 3px 5px;
  border: 1px solid rgb(78, 78, 37)
}

#frmItemManagement table.card-table > tbody > tr.row-collapse > td, #frmItemManagement table.card-table > thead > tr.row-collapse > td {
  padding: 0.2rem calc(0.75rem*.5) 0;
}

#frmItemManagement .statusBtn, #frmItemManagement .statusBtn:hover {
  background-color: #0c7bab;
  color: #fff
}

#frmItemManagement .s-col-padding-bottom-08 {
  padding-bottom: 0.8rem !important;
}

#frmItemManagement .refresh-data {
  padding: 10px 20px;
}

#frmItemManagement .header-title {
  line-height: 2rem;
}

#frmItemManagement .header-sub-title {
  line-height: 0rem;
}

#frmItemManagement .filter-item label {
  width: 100%;
  display: block;
}

#frmItemManagement .filter-item .label-search-title {
  display: block;
  width: 14%;
  float: left;
  margin-top: 6px;
  font-size: .875rem;
}

#frmItemManagement .filter-item .search-text {
  width: 65%;
  float: left;
}

#frmItemManagement .filter-item .search-button {
  width: 15%;
  float: none;
  padding-top: 6px;
}

#frmItemManagement .margin-left-0 {
  margin-left: 0rem;
}

#frmItemManagement .table-outer {
  border: 1px solid #c9c9c9;
}

#frmItemManagement table input[type="checkbox"] {
  width: 16px;
  height: 16px;
  display: inline;
  margin-top: 6px;
}

#frmItemManagement .checkbox-chkall, #frmItemManagement .checkbox-chkall input[type="checkbox"] {
  margin-left: 20px;
  vertical-align: top;
}

#frmItemManagement table .checkbox-select-text {
  margin: 2px 0 0 4px;
  position: absolute;
  font-weight: bold;
}

#frmItemManagement table .status-box {
  text-align: center;
  vertical-align: top;
  padding-top: 7px !important;
}

#frmItemManagement .vertical-align-top {
  vertical-align: top;
}

#frmItemManagement .text-align-center {
  text-align: center;
}
/* ItemManagement.component.html CSS - END*/

/* BatchAddress.component.html CSS - START*/

#frmBatchAddress span.status-Fail {
  background-color: red;
  color: #fff;
  padding: 3px;
}

#frmBatchAddress span.status-Updated {
  background-color: green;
  color: #fff;
  padding: 3px;
}

#frmBatchAddress span.status-New {
  background-color: yellow;
  color: #000;
  padding: 3px 5px;
  border: 1px solid rgb(78, 78, 37)
}

#frmBatchAddress table.card-table > tbody > tr.row-collapse > td, #frmBatchAddress table.card-table > thead > tr.row-collapse > td {
  padding: 0.2rem calc(0.75rem*.5) 0;
}

#frmBatchAddress .statusBtn, #frmBatchAddress .statusBtn:hover {
  background-color: #0c7bab;
  color: #fff
}

#frmBatchAddress .s-col-padding-bottom-08 {
  padding-bottom: 0.8rem !important;
}

#frmBatchAddress .refresh-data {
  padding: 10px 20px;
}

#frmBatchAddress .header-title {
  line-height: 2rem;
}

#frmBatchAddress .header-sub-title {
  line-height: 0rem;
}

#frmBatchAddress .filter-item label {
  width: 100%;
  display: block;
}

#frmBatchAddress .filter-item .label-search-title {
  display: block;
  width: 14%;
  float: left;
  margin-top: 6px;
  font-size: .875rem;
}

#frmBatchAddress .filter-item .search-text {
  width: 65%;
  float: left;
}

#frmBatchAddress .filter-item .search-button {
  width: 15%;
  float: none;
  padding-top: 6px;
}

#frmBatchAddress .margin-left-0 {
  margin-left: 0rem;
}

#frmBatchAddress .table-outer {
  border: 1px solid #c9c9c9;
}

#frmBatchAddress table input[type="checkbox"] {
  width: 16px;
  height: 16px;
  display: inline;
  margin-top: 6px;
}

#frmBatchAddress .checkbox-chkall, #frmBatchAddress .checkbox-chkall input[type="checkbox"] {
  margin-left: 20px;
  vertical-align: top;
}

#frmBatchAddress table .checkbox-select-text {
  margin: 2px 0 0 4px;
  position: absolute;
  font-weight: bold;
}

#frmBatchAddress table .status-box {
  text-align: center;
  vertical-align: top;
  padding-top: 7px !important;
}

#frmBatchAddress .vertical-align-top {
  vertical-align: top;
}

#frmBatchAddress .text-align-center {
  text-align: center;
}

#frmBatchAddress span.status-ValidationFailed {
  background-color: red;
  color: #fff;
  padding: 3px;
}

#frmBatchAddress span.status-Validated {
  background-color: green;
  color: #fff;
  padding: 3px;
  padding-left: 25px;
  padding-right: 25px;
}

#frmBatchAddress span.status-NotValidated {
  background-color: yellow;
  color: #000;
  padding: 3px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid rgb(78, 78, 37)
}
/* BatchAddress.component.html CSS - END*/

.border-padding-0 {
  border: 0px;
  padding: 0px
}

.padding-left-20 {
  padding-left: 20px !important;
}

.padding-left-0 {
  padding-left: 0px;
}

.padding-left-0-margin-bottom-24 {
  padding-left: 0px !important;
  margin-bottom: 24px;
}

.display-block {
  display: block !important;
}

.ul-decoration-none {
  list-style-type: none;
}
/* BatchAddress.component.html CSS - END*/


/* Exemption.component.html CSS - START*/
.exemption-status-valid {
  background-color: green;
  color: #fff;
  padding: 3px 13px;
  border-radius: 6px 6px;
}

.requestMethod-fieldset {
  margin-top: -14px;
  width: 53%;
  display: inline-block;
  vertical-align: top;
}

.requestMethodInfo-infobox-fieldset {
  width: 40%;
  display: inline-block;
  margin-top: 0px !important;
}

.requestMethodInfo-infobox-banner {
  display: none;
  background-color: #cfedfa;
  color: #000;
  position: absolute;
  z-index: 1;
}

.exemptionRequestErrorDialog table td {
  padding-left: 5px;
  padding-right: 5px;
}

.dialog-content-header {
  font-size: 17px;
}

.dialog-content-subheader {
  font-size: 14px;
}

/* Exemption.component.html CSS - END*/

/*Home.component.html CSS - START*/

.home-manage-integration-h2 {
  margin-left: 25%;
}

.home-tiles-outercontainer {
  width: 60%;
  text-align: right;
  margin: 0 auto;
}

.home-calculateactive-container {
  text-align: center;
  margin-bottom: 25px;
  margin-right: 15px;
}

.home-tiles-container {
  text-align: center;
  margin-bottom: 12px;
}

.home-tiles-lineheight {
  line-height: 1.2rem !important;
}

.home-avataxframe-sbox {
  text-align: center;
  display: none;
}

.display-inline {
  display: inline;
}

.display-inline-padding-0-20 {
  display: inline;
  padding: 0px 20px;
}

.helplink-home-1 {
  margin-left: 37px;
}

.helpicon-in-header {
  color: #0C7BAB;
}

.home-info-detail-container {
  border-left: 1px solid #e7e7e7;
  padding-left: 35px;
}

.home-info-detail-h3 {
  color: grey;
}

  .home-info-detail-h3 span {
    font-weight: bold;
    color: #0c7bab;
  }

.home-info-detail-line {
  margin-top: 7px;
}


/*Home.component.html CSS - END*/

.learnmore-container {
  margin: auto;
  width: 50%;
  padding: 10px;
  max-width: 800px !important;
}

.learnmore-video {
  width: 100%;
  height: 59%;
}


.learnmore-container .videoIframeContainer {
  margin-top: 60px;
}

.learnmore-container .videoIframe {
  height: 227px;
  width: 100%;
  border: 0px;
  padding: 0px 10px 0px 30px;
}

.header-learnmore {
  top: -34px;
  right: 75px;
  position: absolute;
}


.header-learnmore-buttons {
  top: 12px;
  right: 75px;
  position: absolute;
  z-index: 9999;
}

  .header-learnmore-buttons .get-started-btn {
    background-color: #f60 !important;
    border: 1px solid #ec8b39 !important;
    color: #fff !important;
    cursor: pointer !important;
    width: 108px;
  }

  .header-learnmore-buttons .signin {
    background-color: #0a97d1 !important;
    border: 1px solid #2a8aa0 !important;
    color: #fff !important;
    cursor: pointer !important;
    width: 108px;
  }

/* connectorsettings.component.html - START */

.rdf-header-title {
  font-size: 1.125rem !important;
  line-height: 1.875rem !important;
  font-weight: 600;
  margin: -13px 0 0 22px;
}

.dropdown-menu {
  border: 1px solid rgb(161, 159, 159);
  z-index: 1;
  max-height: 200px !important;
  overflow-y: auto;
}

  .dropdown-menu .dropdown-item {
    display: block;
    border: 0px;
    width: 100%;
    font-weight: 500;
    margin-left: 0px;
    text-align: left;
    background-color: #fff !important;
  }

    .dropdown-menu .dropdown-item .active {
      background: #c0eaf9;
    }

.search-container img {
  top: 5px;
  right: 6px;
  position: absolute;
  width: 25px;
}

/* connectorsettings.component.html - END */

.tooltip {
  background-color: #000;
  color: #fff;
  padding: 3px 7px;
}

/* integration.component.html - START */


.integration-title {
  width: 60%;
}

.integration-title-btn {
  float: right;
  margin-top: -57px;
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--color-blue-dark);
  padding: 0px;
}

.integration-box-add {
  width: 270px;
  height: 240px;
  border: 1px solid #ccc;
  float: left;
  margin-right: 30px;
  padding: 10px;
  margin-bottom: 30px;
}

  .integration-box-add .add-symbol {
    width: 100% !important;
    margin: 0 auto;
    position: relative;
    text-align: center;
    height: 60% !important;
    padding-top: 18%;
    align-content: center;
  }

  .integration-box-add .title {
    width: 100% !important;
    margin: 0 auto;
    position: relative;
    text-align: left;
    height: 40% !important;
    font-size: 14px;
    align-content: center;
    /*font-weight: 600;*/
    /*line-height: 30px;*/
  }

.integration-box-add-company {
  width: 270px;
  height: 240px;
  border: 1px solid #ccc;
  float: left;
  margin-right: 30px;
  padding: 10px;
  margin-bottom: 30px;
}

  .integration-box-add-company .add-symbol {
    display: grid;
    width: 100% !important;
    margin: 0 auto;
    position: relative;
    text-align: center;
    height: 100% !important;
    align-content: center !important;
  }

  .integration-box-add-company .title {
    width: 100% !important;
    margin: 0 auto;
    position: relative;
    text-align: center;
    height: 40% !important;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
  }

.integration-box-add .sub-title {
  font-weight: 600;
}

.integration-box-add .qbo-company-title {
  font-size: 12px;
  margin-top: 10px;
}

.integration-box-add .qbo-company-name {
  font-size: 12px;
  font-style: italic;
  color: #6F6F6F;
}

.integration-box-add .qbo-company-name-highlight {
  font-size: 12px;
  font-weight: 600;
  color: #22821D;
}

.integration-box-add .ava-company-title {
  font-size: 12px;
  margin-top: 10px;
}

.integration-box-add .ava-company-editable-select {
  /* width:70%;  */
  float: left;
  margin-right: 5%;
}

.integration-box-add .ava-company-editable-icon {
  width: 20%;
  float: left;
}

.integration-box-add .btn-container {
  clear: both;
  margin-top: 10px;
}

.integration-box-add .btn {
  width: 100%;
}

.integration-box-add .btn-blue {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--color-blue-dark);
  padding: 0px;
}

.user-tag {
  align-items: center;
  background-color: var(--color-gray-lighter);
  border: 1px solid var(--color-gray-lighter);
  border-radius: 4px;
  color: var(--color-gray-darkest);
  display: inline-flex;
  font-weight: 600;
  justify-content: space-between;
  margin-right: var(--margin-xs);
  min-height: 24px;
  padding: 0 var(--padding-xs);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: baseline;
  white-space: nowrap;
}

/* integration.component.html - END */

.grey-blocks {
  background: #f2f2f2;
  color: #bbb;
}

  .grey-blocks .qbo-company-name-highlight {
    color: #bbb;
  }

.disconnectedPage .contained-bg {
  background: #eee;
  padding: 10px 30px;
  border-radius: 5px;
}

.disconnectedPage .page-container {
  width: 800px;
  margin: 0 auto;
}

.disconnectedPage p {
  font-size: 21px;
  line-height: 1.6;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.connectToQuickBooksDialogBody {
  text-align: center;
  width: 700px;
}
